import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChainId, ThirdwebProvider } from '@thirdweb-dev/react';

import { StateContextProvider } from './context';
import App from './App';
import './index.css';

export const Main = () => {
  const sdkOptions = {
    readonlySettings: {
      rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com", // force read calls to go through your own RPC url
      chainId: 11155111, // reduce RPC calls by specifying your chain ID
    },
  }
return <ThirdwebProvider desiredChainId={11155111} sdkOptions={sdkOptions}> 
      <StateContextProvider>
        <App />
      </StateContextProvider>
  </ThirdwebProvider> 

}