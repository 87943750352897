import React, { useState, useEffect } from 'react';
import logo from './image/S.png'
import navi from './image/bg101.webp'
import { useNavigate, useLocation } from "react-router-dom"
import { FaSearch, FaUser, FaBars, FaTimes } from "react-icons/fa";
import Avatar from 'react-avatar';
import { motion, AnimatePresence } from 'framer-motion';

const Navbar = ({ textColorClassName, showCustomButton }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdown2Open, setIsDropdown2Open] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleDropdown = () => setIsDropdownOpen(prev => !prev);
    const toggleDropdown2 = () => setIsDropdown2Open(prev => !prev);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const isFAQPage = location.pathname === '/faq';
const isTermsPage=location.pathname==='/t&c'
    return (
        <motion.nav 
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-black bg-opacity-50' : ' '}`}
        >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-20">
                    <div className="flex items-center space-x-4">
                        <motion.button 
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="text-sm font-bold bg-gradient-to-r from-red-500 to-pink-500 hover:from-red-600 hover:to-pink-600 text-white px-4 py-2 rounded-full transition duration-300 ease-in-out shadow-lg"
                        >
                            DOCS
                        </motion.button>
                        <motion.button 
                            onClick={() => navigate('/')} 
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="mr-4"
                        >
                            <img src={logo} className="h-10 w-auto" alt="SEIZE Logo" />
                        </motion.button>
                        <motion.button 
                            onClick={toggleDropdown2} 
                            whileHover={{ scale: 1.05, color: "#EF4444" }}
                            className={`flex items-center text-sm font-medium transition duration-300 ${isFAQPage||isTermsPage ? 'text-black' : 'text-white'}`}
                        >
                            <svg className="w-5 h-5 mr-1 animate-pulse" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm50.7-186.9L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"></path></svg>
                            SEIZE
                        </motion.button>
                    </div>

                    <div className="hidden md:flex items-center space-x-6">
                        <motion.div 
                            className="relative group"
                            whileHover={{ scale: 1.05 }}
                        >
                            <input type="text" placeholder="Search campaigns" className="bg-gray-800 text-white rounded-full py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-red-500 transition-all duration-300 w-48 group-hover:w-64" />
                           
                        </motion.div>
                        <motion.a 
                            onClick={() => navigate('/upload')} 
                            whileHover={{ scale: 1.1, color: "#EF4444" }}
                            className="text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300 cursor-pointer"
                        >
                            Campaigns
                        </motion.a>
                        <motion.button 
                            onClick={() => navigate('/startcampaign')} 
                            whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(239, 68, 68)" }}
                            whileTap={{ scale: 0.95 }}
                            className="bg-gradient-to-r from-red-500 to-pink-500 hover:from-red-600 hover:to-pink-600 text-white px-6 py-2 rounded-full text-sm font-bold transition duration-300 ease-in-out shadow-lg"
                        >
                            START A CAMPAIGN
                        </motion.button>
                        {showCustomButton && (
                            <div className="relative">
                                <motion.button 
                                    onClick={toggleDropdown} 
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    className="flex items-center text-sm focus:outline-none transition duration-300"
                                >
                                    <Avatar name="User" size="40" round={true} />
                                </motion.button>
                                <AnimatePresence>
                                    {isDropdownOpen && (
                                        <motion.div 
                                            initial={{ opacity: 0, scale: 0.95 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 0.95 }}
                                            transition={{ duration: 0.2 }}
                                            className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                                        >
                                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                                                <motion.a whileHover={{ backgroundColor: "#EF4444", color: "white" }} onClick={() => navigate('/Profile')} className="block px-4 py-2 text-sm text-gray-700 transition duration-200 cursor-pointer" role="menuitem">My Campaigns</motion.a>
                                                <motion.a whileHover={{ backgroundColor: "#EF4444", color: "white" }} onClick={() => navigate('/Profile')} className="block px-4 py-2 text-sm text-gray-700 transition duration-200 cursor-pointer" role="menuitem">Profile</motion.a>
                                                <motion.a whileHover={{ backgroundColor: "#EF4444", color: "white" }} onClick={() => navigate('/Setting')} className="block px-4 py-2 text-sm text-gray-700 transition duration-200 cursor-pointer" role="menuitem">Settings</motion.a>
                                                <motion.a whileHover={{ backgroundColor: "#EF4444", color: "white" }} className="block px-4 py-2 text-sm text-gray-700 transition duration-200 cursor-pointer" role="menuitem">Logout</motion.a>
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        )}
                    </div>

                    <div className="md:hidden">
                        <motion.button 
                            onClick={toggleDropdown} 
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-red-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white transition duration-300"
                        >
                            <span className="sr-only">Open main menu</span>
                            {isDropdownOpen ? (
                                <FaTimes className="h-6 w-6" />
                            ) : (
                                <FaBars className="h-6 w-6" />
                            )}
                        </motion.button>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {isDropdownOpen && (
                    <motion.div 
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        className={`md:hidden bg-black bg-opacity-90`}
                    >
                        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                            <motion.a 
                                whileHover={{ scale: 1.05, backgroundColor: "#EF4444" }}
                                onClick={() => navigate('/upload')} 
                                className="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium transition duration-300 cursor-pointer"
                            >
                                Campaigns
                            </motion.a>
                            <motion.button 
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => navigate('/startcampaign')} 
                                className="w-full text-left bg-gradient-to-r from-red-500 to-pink-500 hover:from-red-600 hover:to-pink-600 text-white px-3 py-2 rounded-md text-base font-medium transition duration-300"
                            >
                                START A CAMPAIGN
                            </motion.button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {isDropdown2Open && (
                    <motion.div 
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        className="bg-black bg-opacity-90 py-6"
                    >
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                                <div className="space-y-4">
                                    <h3 className="text-xl font-bold text-white mb-4 border-b-2 border-red-500 pb-2">Discover</h3>
                                    <motion.a whileHover={{ color: "#EF4444", x: 5 }} className="block text-gray-300 transition duration-300 cursor-pointer">Learn</motion.a>
                                    <motion.a whileHover={{ color: "#EF4444", x: 5 }} onClick={() => navigate('/faq')} className="block text-gray-300 transition duration-300 cursor-pointer">FAQs</motion.a>
                                </div>
                                <div className="space-y-4">
                                    <h3 className="text-xl font-bold text-white mb-4 border-b-2 border-red-500 pb-2">Legal</h3>
                                    <motion.a whileHover={{ color: "#EF4444", x: 5 }} onClick={() => navigate('/t&c')} className="block text-gray-300 transition duration-300 cursor-pointer">Terms & Conditions</motion.a>
                                    <motion.a whileHover={{ color: "#EF4444", x: 5 }} onClick={()=>navigate('/contact')} className="block text-gray-300 transition duration-300 cursor-pointer">Contact</motion.a>
                                </div>
                                <div>
                                    <motion.a 
                                        whileHover={{ scale: 1.05 }}
                                        onClick={() => navigate('/startcampaign')} 
                                        className="block group cursor-pointer"
                                    >
                                        <div className="relative overflow-hidden rounded-lg shadow-lg">
                                            <img src={navi} alt="Start Here" className="w-full transition duration-300 transform group-hover:scale-110" />
                                            <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
                                            <div className="absolute bottom-16 left-4 right-4 text-center">
                                                <span className="text-3xl font-bold text-white">START </span>
                                                <span className="text-3xl font-bold text-red-500">HERE</span>
                                            </div>
                                        </div>
                                    </motion.a>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.nav>
    );
};

export default Navbar;