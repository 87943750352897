'use client'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import React, { useState, useEffect } from 'react';
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import UpperPart from './Components/UpperPart';
import {motion} from 'framer-motion'
import video from './images/h.mp4'
function App() {
  const navigate = useNavigate();

  return (
    <div className="bg-black min-h-screen">
      <UpperPart />

      <div className="relative h-screen overflow-hidden">
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 w-full h-full object-cover z-0"
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
        <div className="absolute inset-0 flex items-center justify-center z-20">
          <div className="text-center px-4 sm:px-6 lg:px-8">
            <motion.h1 
              className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <motion.p 
                className="mb-2"
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 1 }}
              >
                IT'S
              </motion.p>
              <motion.p 
                className="mb-2"
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 1.5 }}
              >
                YOUR
              </motion.p>
              <motion.p 
                className="opportunity-text text-red-500 transition-transform duration-300 ease-out hover:-translate-y-1 text-3xl sm:text-4xl md:text-5xl" 
                data-value="OPPORTUNITY"
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.8, delay: 2, type: 'spring' }}
              >
                OPPORTUNITY
              </motion.p>
            </motion.h1>
            <motion.button 
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 sm:py-4 sm:px-8 rounded-lg shadow-lg transform transition duration-300 ease-in-out hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 mt-6 sm:mt-8 text-sm sm:text-base"
              onClick={() => navigate('/upload')}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 2.5 }}
              whileHover={{ scale: 1.05, boxShadow: '0px 0px 8px rgb(255,0,0)' }}
              whileTap={{ scale: 0.95 }}
            >
              SEIZE CAMPAIGNS
            </motion.button>
          </div>
        </div>
      </div>

      <div className="bg-black text-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">Join Us</h2>
          <div className="flex justify-center space-x-8">
            <a href='https://x.com/SEIZE_web3' target='_blank' rel="noopener noreferrer" className="transform transition duration-300 hover:scale-110">
              <FaXTwitter className="w-12 h-12" />
            </a>
            <a href='https://discord.gg/q49drhkrXD' target='_blank' rel="noopener noreferrer" className="transform transition duration-300 hover:scale-110">
              <FaDiscord className="w-12 h-12 text-[#5865f2]" />
            </a>
          </div>
        </div>
      </div>

      <footer className="bg-black text-white py-12">
        <div className="container mx-auto px-4">
          <div className="border-b border-black mb-8"></div>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <motion.p 
              className="text-2xl font-bold mb-4 md:mb-0"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Seize Your <span className=" text-red-500">Opportunity</span>
            </motion.p>
            <div className="flex space-x-6">
              <motion.a 
                href="#" 
                className="text-lg hover:text-white transition duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Privacy Policy
              </motion.a>
              <motion.a 
                href="#" 
                className="text-lg hover:text-white transition duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Terms of Service
              </motion.a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
