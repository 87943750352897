import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import './UpperBody.css';

import heroImage from './image/b6.png';
import egoVersusImage from './image/CL.webp';
import futuristicCityscape from './image/b7.png';
import abstractTechnology from './image/img4.png';
import innovationConcept from './image/img5.png';
import Navbar from './Navbar';

const images = [heroImage, egoVersusImage, futuristicCityscape, abstractTechnology, innovationConcept];
const imageTitles = ["Seize", "egoVersus", "Evolve", "Transform", "Inspire"];

function UpperPart() {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const itemsRef = useRef([]);
  const thumbnailsRef = useRef([]);

  const showSlider = (index) => {
    setActiveIndex(index);
    if (index === 1) {
      navigate('/egoVersus');
    }
  };

  const next = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div>
       <div className='text-white w-full fixed top-0 left-0 z-[999] bg-black/25'>
  
  <Navbar textColorClassName={'text-white'}/>
</div>  

      <header>
        
      </header>

      <div className="slider">
        <div className="list">
          {images.map((img, index) => (
            <div
              key={index}
              className={`item ${index === activeIndex ? 'active' : ''}`}
              ref={(el) => (itemsRef.current[index] = el)}
              onClick={() => showSlider(index)}
            >
              <img src={img} alt={`Slider ${index + 1}`} />
              <div className="content flex flex-col items-center justify-center w-full h-full">
                {index === 0 && (
                  <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center w-full px-4">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">Campaigns Made</h2>
                    <p className="text-4xl sm:text-5xl md:text-6xl font-extrabold">1,000+</p>
                  </div>
                )}
                {index === 1 && (
                  <>
                    
                  </>
                )}
                <div className='py-2 w-full'>
                
                </div>
              </div>
              <div className="content flex flex-col items-center justify-center w-full h-full">
                {index === 0 && (
                  <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center w-full px-4">
                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">Campaigns Made</h2>
                    <p className="text-4xl sm:text-5xl md:text-6xl font-extrabold">1,000+</p>
                  </div>
                )}
                {index === 1 && (
                  <>
                    
                  </>
                )}
                <div className='py-2 w-full'>
                
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="arrows ">
        </div>

        <div className="thumbnail">
          {images.map((img, index) => (
            <div
              key={index}
              className={`item ${index === activeIndex ? 'active' : ''}`}
              ref={(el) => (thumbnailsRef.current[index] = el)}
              onClick={() => showSlider(index)}
            >
              <img src={img} alt={`Thumbnail ${index + 1}`} />
              <div className="content">
                {imageTitles[index]}
              </div>
            </div>
          ))}
        </div>
      </div>
              <div className='flex items-center justify-center'>
              </div>
    </div>
  );
}

export default UpperPart;
