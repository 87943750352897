import React from 'react'
import Navbar from '../Components/Navbar'
import './Profile.css'
import Tabs from '../Components/Tabs'

const Profile = () => {
  return (
    <div className='bg-black'>
      <div>
        <Navbar/>
      </div>

      <div>
<Tabs/>

      </div>
    </div>
  )
}

export default Profile
