 

// importing components from react-router-dom package
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

// import Home component
import Main from "./Main";
import Main2 from "./Main2"
// import About component
import Login from "./Components/Login";
// import ContactUs component
import Signup from "./Components/Signup";
import Campaign from "./Components/Campaign";
import Start from "./StartCamapaign/Start";
import Profile from "./Profile/Profile";
// import Upload from "./Upload/Upload";
import Setting from "./Components2/CampaignEditor/Setting"; 
import {Main as Upload} from "./src/main"; 
import FAQs from "./Components/FAQs";
import Terms from "./Components/terms";
import Client1 from "./Components/Client1";
import Contact from "./Components/Contact";

function App() {
    return (
        <>
            {/* This is the alias of BrowserRouter i.e. Router */}
            <Router>
                <Routes>
                    {/* This route is for home component 
          with exact path "/", in component props 
          we passes the imported component*/}
                    <Route
                        exact
                        path="/"
                        element={<Main />}
                    />

                    {/* This route is for about component 
          with exact path "/about", in component 
          props we passes the imported component*/}
                    <Route
                        path="/about"
                        element={<Login />}
                    />
                   
                    <Route
                        path="/contact"
                        element={<Contact />}
                    />
                   

                    {/* This route is for contactus component
          with exact path "/contactus", in 
          component props we passes the imported component*/}
                    <Route
                        path="/signup"
                        element={<Signup />}
                    />
                    <Route
                        path="/sieze"
                        element={<Main2 />}
                    />
                    
                    <Route
                        path="/startcampaign"
                        element={<Start />}
                    />
                    <Route
                        path="/faq"
                        element={<FAQs />}
                    />
                    <Route
                        path="/egoversus"
                        element={<Client1 />}
                    />
                    <Route
                        path="/t&c"
                        element={<Terms />}
                    />
                    <Route
                        path="/Profile"
                        element={<Profile />}
                    />
                     <Route
                        path="/Setting"
                        element={<Setting />}
                    />
                    <Route
                        path="/Upload/*"
                        element={<Upload/>}
                    />

                    {/* If any route mismatches the upper 
          route endpoints then, redirect triggers 
          and redirects app to home component with to="/" */}
                    {/* <Redirect to="/" /> */}
                </Routes>
            </Router>
        </>
    );
}

export default App;