import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Navbar from './Navbar.jsx'
import { ethers } from 'ethers'
import {  contractAddress } from '../constants/contracts.js'
import {  contractABI } from '../constants/abi.js'
import { FaDiscord, FaTwitter, FaSteam } from 'react-icons/fa'
import video2 from '../images/Scene-1.mp4'

const Client1 = () => {
  const [selectedTier, setSelectedTier] = useState(null)
  const [hoveredTier, setHoveredTier] = useState(null) 
  const [currentAccount, setCurrentAccount] = useState("")
  const [tierCounts, setTierCounts] = useState({
    'Tier 1': 0,
    'Tier 2': 0,
    'Tier 3': 0
  })
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  })

  const checkIfWalletIsConnected = async () => {
    try {
      if (!window.ethereum) {
        setSnackbar({
          open: true,
          message: "Please install MetaMask.",
          severity: 'error'
        })
        return
      }

      const accounts = await window.ethereum.request({ method: 'eth_accounts' })

      if (accounts.length) {
        setCurrentAccount(accounts[0])
      } else {
        console.log("No accounts found")
      }
    } catch (error) {
      console.log(error)
      throw new Error("No ethereum object")
    }
  }

  const connectWallet = async () => {
    try {
      if (!window.ethereum) {
        setSnackbar({
          open: true,
          message: "Please install MetaMask.",
          severity: 'error'
        })
        return
      }

      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })

      setCurrentAccount(accounts[0])
    } catch (error) {
      console.log(error)
      throw new Error("No ethereum object")
    }
  }

  const handleTierClick = async (tier) => {
    setSelectedTier(tier === selectedTier ? null : tier)
    const price = tier === 'Tier 1' ? 50 : tier === 'Tier 2' ? 100 : 150

    if (!currentAccount) {
      setSnackbar({
        open: true,
        message: "Please connect your wallet first.",
        severity: 'warning'
      })
      return
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const contract = new ethers.Contract(contractAddress, contractABI, signer)

      const parsedAmount = ethers.utils.parseEther(price.toString())

      await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: currentAccount,
          to: contractAddress,
          gas: '0x5208',
          value: parsedAmount._hex,
        }],
      })

      const transactionHash = await contract.addToFundraising(parsedAmount, tier)

      console.log(`Loading - ${transactionHash.hash}`)
      await transactionHash.wait()
      console.log(`Success - ${transactionHash.hash}`)

      setTierCounts(prevCounts => ({
        ...prevCounts,
        [tier]: prevCounts[tier] + 1
      }))

      setSnackbar({
        open: true,
        message: `Successfully contributed ${price} ETH to ${tier}!`,
        severity: 'success'
      })
    } catch (error) {
      console.log(error)
      setSnackbar({
        open: true,
        message: "An error occurred while processing your transaction.",
        severity: 'error'
      })
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected()
  }, [])

  const getTierClassName = (tier) => {
    const baseClass = "p-6 rounded-lg shadow-lg transition-all duration-300 cursor-pointer"
    const selectedClass = "transform scale-105 ring-4 ring-red-400"
    const hoverClass = hoveredTier === tier ? "transform scale-105" : ""
    return `${baseClass} ${selectedTier === tier ? selectedClass : ''} ${hoverClass} ${tier === 'Tier 1' ? 'bg-gradient-to-br from-red-700 to-red-900' : tier === 'Tier 2' ? 'bg-gradient-to-br from-red-800 to-red-950' : 'bg-gradient-to-br from-red-900 to-black'}`
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  }

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const snackbarVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: 50, opacity: 0 }
  };

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 w-full z-10">
        <Navbar/>
      </div>
      <div className="relative w-full h-screen overflow-hidden">
        <video 
          src={video2} 
          muted 
          autoPlay 
          loop 
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover"
        ></video>
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
        <button
          onClick={() => {
            const mainContent = document.querySelector('.container');
            mainContent.scrollIntoView({ behavior: 'smooth' });
          }}
          className="absolute bottom-10 left-1/2 transform -translate-x-1/2 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out flex items-center space-x-2 group"
        >
          <span>Explore</span>
          <svg
            className="w-5 h-5 group-hover:translate-y-1 transition-transform duration-300"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
          </svg>
        </button>
      </div>
      <motion.div 
        className="relative z-20 min-h-screen bg-gradient-to-b from-transparent to-red-900 text-white"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="container mx-auto px-4 py-12 pt-24">
          <motion.div 
            className="bg-gradient-to-r from-red-800 to-black p-8 rounded-xl shadow-2xl mb-12"
            variants={itemVariants}
          >
            <h2 className="text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-white">EgoVersus: The First Strike $50,000 Fundraising Campaign</h2>
            <p className="text-lg text-gray-200 leading-relaxed">
              We are thrilled to announce our $50,000 fundraising campaign to fuel the development of EgoVersus: The First Strike. Your investment will propel us towards creating innovative features and an unparalleled gaming experience. With unique investment packages and milestone-driven development, every contribution brings us closer to revolutionizing the future of gaming.
            </p>
          </motion.div>

          <motion.div 
            className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12"
            variants={containerVariants}
          >
            {['Tier 1', 'Tier 2', 'Tier 3'].map((tier) => (
              <motion.div 
                key={tier}
                className={getTierClassName(tier)}
                onClick={() => handleTierClick(tier)}
                onMouseEnter={() => setHoveredTier(tier)}
                onMouseLeave={() => setHoveredTier(null)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                variants={itemVariants}
              >
                <h3 className="text-2xl font-semibold mb-3 text-white">{tier}: {tier === 'Tier 1' ? 'Rare' : tier === 'Tier 2' ? 'Epic' : 'Legendary'}</h3>
                <p className="font-bold mb-2 text-red-300">Price: ${tier === 'Tier 1' ? '50' : tier === 'Tier 2' ? '100' : '150'} (Max {tier === 'Tier 1' ? '300' : tier === 'Tier 2' ? '200' : '100'} Packages)</p>
                <ul className="list-disc list-inside text-gray-200">
                  <li>{tier === 'Tier 1' ? '1' : tier === 'Tier 2' ? '2' : '5'} Alter Ego Hunter NFTs (no hold required)</li>
                  <li>Early Investor Role & Access to Early Investor Channel</li>
                  <li>Exclusive {tier === 'Tier 1' ? 'Rare' : tier === 'Tier 2' ? 'Epic' : 'Legendary'} Weapon Skin Package</li>
                  <li>Exclusive {tier === 'Tier 1' ? 'Rare' : tier === 'Tier 2' ? 'Epic' : 'Legendary'} Calling Card & Emblem</li>
                  <li>Access to Early Beta Testing</li>
                  {tier === 'Tier 3' && <li>Custom Legendary 3D Character Added to the Game</li>}
                </ul>
                <p className="mt-4 text-center font-bold text-red-300">
                  {tierCounts[tier]}/{tier === 'Tier 1' ? '300' : tier === 'Tier 2' ? '200' : '100'} Funded
                </p>
              </motion.div>
            ))}
          </motion.div>

          <motion.div 
            className="bg-gradient-to-r from-black to-red-800 p-8 rounded-xl shadow-2xl"
            variants={itemVariants}
          >
            <h2 className="text-3xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-white">Development Milestones</h2>
            <ul className="space-y-6">
              {[
                { title: "25% Raised – Dynamic Asset Loot Box System", description: "Unlock a new system for randomized in-game items and NFTs, adding excitement to gameplay." },
                { title: "50% Raised – Asset Naming Service", description: "Personalize in-game assets and NFTs with unique names for deeper customization." },
                { title: "75% Raised – Game Asset Renting Service", description: "Rent in-game items and NFTs to other players, opening new earning potentials." },
                { title: "100% Raised – Asset Upgrading Service", description: "Enhance and upgrade in-game items and NFTs for more power and visual uniqueness." }
              ].map((milestone, index) => (
                <motion.li 
                  key={index}
                  className="bg-gradient-to-r from-red-900 to-black p-4 rounded-lg"
                  variants={itemVariants}
                >
                  <h4 className="font-semibold text-xl text-red-300 mb-2">{milestone.title}</h4>
                  <p className="text-gray-200">{milestone.description}</p>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          <motion.p 
            className="mt-12 text-xl text-center text-gray-300 leading-relaxed"
            variants={itemVariants}
          >
            This fundraising campaign offers you the unique opportunity to not just be part of the game, but to actively shape its development. With exclusive NFTs, early beta access, and groundbreaking features on the horizon, your investment will directly influence The EgoVerse as we push the boundaries of gaming into uncharted territories.
          </motion.p>
        </div>
      </motion.div>
      <footer className="bg-black py-12">
        <div className="container mx-auto px-4">
          <div className="flex justify-center space-x-8">
            <a href="https://discord.gg/wYCqjCzYhW" target="_blank" rel="noopener noreferrer" className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white p-3 rounded-full hover:from-purple-700 hover:to-indigo-700 transition-all duration-300 transform hover:scale-110 shadow-lg">
              <FaDiscord size={28} />
            </a>
            <a href="https://x.com/TheEgoVerse" target="_blank" rel="noopener noreferrer" className="bg-gradient-to-r from-blue-400 to-blue-600 text-white p-3 rounded-full hover:from-blue-500 hover:to-blue-700 transition-all duration-300 transform hover:scale-110 shadow-lg">
              <FaTwitter size={28} />
            </a>
            <a href="https://store.steampowered.com/app/3076680/EgoVersus_The_First_Strike/ " target="_blank" rel="noopener noreferrer" className="bg-gradient-to-r from-gray-700 to-gray-900 text-white p-3 rounded-full hover:from-gray-800 hover:to-black transition-all duration-300 transform hover:scale-110 shadow-lg">
              <FaSteam size={28} />
            </a>
          </div>
        </div>
      </footer>
      <AnimatePresence>
        {snackbar.open && (
          <motion.div
            className={`fixed right-4 bottom-4 px-6 py-4 rounded-xl shadow-2xl z-50 ${
              snackbar.severity === 'success' ? 'bg-gradient-to-r from-green-500 to-green-600' :
              snackbar.severity === 'error' ? 'bg-gradient-to-r from-red-500 to-red-600' :
              snackbar.severity === 'warning' ? 'bg-gradient-to-r from-black to-yellow-600' : 'bg-gradient-to-r from-blue-500 to-blue-600'
            } text-white backdrop-blur-sm bg-opacity-90`}
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            transition={{ type: "spring", stiffness: 500, damping: 40 }}
          >
            <div className="flex items-center">
              <span className="mr-3 text-2xl">
                {snackbar.severity === 'success' ? '🎉' :
                 snackbar.severity === 'error' ? '❌' :
                 snackbar.severity === 'warning' ? '⚠️' : 'ℹ️'}
              </span>
              <p className="font-semibold text-lg">{snackbar.message}</p>
              <button
                onClick={handleCloseSnackbar}
                className="ml-4 text-white hover:text-gray-200 focus:outline-none transition-transform duration-200 hover:scale-110"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Client1
