import React from 'react'
import { FaDiscord, FaTwitter, FaEnvelope } from 'react-icons/fa'
import { motion } from 'framer-motion'
import Navbar from './Navbar'

const Contact = () => {
  return (
    <div>
<div>
    <Navbar/>
</div>
    <div className="bg-gradient-to-br from-[#1c1c24] to-[#2d2d3a] min-h-screen flex items-center justify-center p-4">
      <motion.div 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-[#3a3a43] p-10 rounded-2xl shadow-2xl max-w-lg w-full"
        >
        <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 mb-8 text-center py-9">Get in Touch</h1>
        <div className="space-y-6">
          <ContactItem 
            icon={<FaDiscord className="text-[#7289da] text-4xl" />}
            platform="Discord"
            contact="Join our community"
            link="https://discord.gg/q49drhkrXD"
            />
          <ContactItem 
            icon={<FaTwitter className="text-[#1da1f2] text-4xl" />}
            platform="Twitter"
            contact="@yourhandle"
            link="https://x.com/SEIZE_web3"
          />
          <ContactItem 
            icon={<FaEnvelope className="text-[#ea4335] text-4xl" />}
            platform="Email"
            contact="contact@example.com"
            link="mailto:seize@example.com"
            />
        </div>
      </motion.div>
    </div>
    </div>
  )
}

const ContactItem = ({ icon, platform, contact, link }) => (
  <motion.a 
    href={link} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="flex items-center p-6 bg-[#2c2f32] rounded-xl hover:bg-[#4b5264] transition duration-300 shadow-md"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    >
    <div className="bg-[#1c1c24] p-3 rounded-full mr-4">
      {icon}
    </div>
    <div>
      <h2 className="text-white text-xl font-semibold">{platform}</h2>
      <p className="text-gray-400 mt-1">{contact}</p>
    </div>
  </motion.a>
)

export default Contact
